const Divider = () => {
  return (
    <div
      style={{
        borderBottom: "solid 1px #F1F1F2",
        height: "1px",
        width: "100%"
      }}
    />
  )
}

export default Divider
