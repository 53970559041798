import React from "react"

type Props = {
  children: React.ReactNode
}

const CommonLayout = ({ children }: Props) => {
  return <>{children}</>
}

export default CommonLayout
