import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import Backend, { HttpBackendOptions } from "i18next-http-backend"

const DEFAULT_LANGUAGE = "en"

i18n
  .use(Backend) //
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init<HttpBackendOptions>({
    backend: {
      // loadPath: "/locales/{{lng}}/{{ns}}.json",
      loadPath: `/locales/{{lng}}/{{ns}}.json?ts=${process.env.VITE_BUILD_TIMESTAMP}`
    },
    fallbackLng: DEFAULT_LANGUAGE,
    debug: false,
    // lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export const getCurrentLanguage = () => {
  return i18n.language === "th" ? "th" : DEFAULT_LANGUAGE
}

export default i18n
