import { createFileRoute } from "@tanstack/react-router"
import { useTranslation } from "react-i18next"
import { getCurrentLanguage } from "@/utils/i18n"
import { useEffect, useMemo } from "react"
import useAppConfigStore from "@/store/useAppConfigStore"

const GhostLayout = () => {
  const { t } = useTranslation(["error"])
  const currentLang = useMemo(() => getCurrentLanguage(), [])
  const helpLink = `http://www.ascendbit.com/${currentLang}/loyalty-help-center`
  const { setTitle } = useAppConfigStore()
  useEffect(() => {
    setTitle(t("error.401.pageTitle"))
  }, [])

  return (
    <div className="h-screen flex justify-center items-start bg-[#F4F6F8]">
      <div className="mt-36 flex flex-col justify-center items-center max-w-[295px] text-center">
        <img
          alt="error ghost"
          src="/images/error-ghost.svg"
          width={266.67}
          height={200}
        />
        <span className="text-l font-semibold text-tmn-black-tint-30 mt-8">
          {t("error.401.title")}
        </span>
        <span className="text-sm text-tmn-black-tint-50 mt-4 mb-3">
          {t("error.401.description")}
        </span>
        <a href={helpLink}>
          <div className="text-sm font-semibold text-tmn-orange-tint-0 mt-8 mb-3">
            {t("error.common.help")}
          </div>
        </a>
      </div>
    </div>
  )
}

export const Route = createFileRoute("/401")({
  component: GhostLayout
})

export default GhostLayout
