import mixpanel, { OverridedMixpanel } from "mixpanel-browser"
import { getConfig } from "./config"

export const initMixpanel = (token: string): OverridedMixpanel => {
  const { enableTrackingDebug } = getConfig()
  mixpanel.init(token, {
    debug: enableTrackingDebug
  })
  return mixpanel
}

export default mixpanel
