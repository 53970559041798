import { Toast, ToastClose, ToastProvider, ToastViewport } from "@/components/ui/toast"
import { useToast } from "@/components/ui/use-toast"

import CheckIcon from "@/assets/icons/ic_green_check.svg?react"

interface ToasterProps {
  viewportStyles?: React.CSSProperties
}

export function Toaster({ viewportStyles }: ToasterProps) {
  const { toasts } = useToast()

  return (
    <ToastProvider swipeDirection="up">
      {toasts.map(function ({ id, title, action, ...props }) {
        return (
          <Toast key={id} {...props}>
            <div className="flex flex-row gap-2 items-center">
              <CheckIcon />
              <h3 className="body-large text-white">{title}</h3>
            </div>
            {action}
            <ToastClose />
          </Toast>
        )
      })}
      <ToastViewport style={viewportStyles} />
    </ToastProvider>
  )
}
