export default class RequestError extends Error {
  errorCode: string
  errorMessage: string

  constructor(
    message: string,
    { errorCode, errorMessage }: { errorCode: string; errorMessage: string }
  ) {
    super(message)
    this.errorCode = errorCode
    this.errorMessage = errorMessage
  }
}
