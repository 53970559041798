const configValue = {
  dev: {
    assetUrl: "https://lat-assets-np.abc-dev.network",
    userTierEnabled: true,
    mediaUrl: "https://media-d.ascendbit.io",
    enableTrackingDebug: true
  },
  qa: {
    assetUrl: "https://lat-assets-np.abc-dev.network",
    userTierEnabled: false,
    mediaUrl: "https://media-q.ascendbit.io",
    enableTrackingDebug: true
  },
  stg: {
    assetUrl: "https://lat-assets-np.abc-dev.network",
    userTierEnabled: false,
    mediaUrl: "https://media-s.ascendbit.io",
    enableTrackingDebug: false
  },
  prod: {
    assetUrl: "https://lat-assets.ascendbit.network",
    userTierEnabled: false,
    mediaUrl: "https://media.ascendbit.net",
    enableTrackingDebug: false
  }
}

const configs: { [key: string]: object } = {
  localhost: configValue.dev,
  "tmn-loyalty-dev.abc-dev.network": configValue.dev,
  "tmn-loyalty-qa.abc-dev.network": configValue.qa,
  "tmn-loyalty-stg.abc-dev.network": configValue.stg,
  "tmn-loyalty.ascendbit.net": configValue.prod,
  "abc-loyalty-qa.abc-dev.network": configValue.qa,
  "abc-loyalty-stg.abc-dev.network": configValue.stg,
  "abc-loyalty.ascendbit.net": configValue.prod
}

export const getConfig = (hostname: string = window.location.hostname) => {
  return configs[hostname] as typeof configValue.dev
}
