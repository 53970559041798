import { Button } from "@/components/ui/button"
import useAppConfigStore from "@/store/useAppConfigStore"
import { createLazyFileRoute, useRouterState } from "@tanstack/react-router"
import { useEffect } from "react"
import { Trans, useTranslation } from "react-i18next"
import IcClose from "@/assets/icons/ic_close.svg?react"
import { trackTMNCoinError } from "@/api/tracking.api"

export const Route = createLazyFileRoute("/_fullscreen-layout/error")({
  component: ErrorPage
})

type Props = {
  code?: string
  message?: string
}

export function ErrorPage({ code, message }: Props) {
  const {
    location: {
      state: { trackCode, trackMessage }
    }
  } = useRouterState()
  const { setIsEnableNavbar, setTitle, user } = useAppConfigStore()
  const { t } = useTranslation(["error"], { keyPrefix: "error.maintenance" })
  useEffect(() => {
    setIsEnableNavbar(false)
    setTitle("We're down for maintenance")
    return () => {
      setIsEnableNavbar(true)
    }
  }, [])

  useEffect(() => {
    if (code && message) {
      trackTMNCoinError({
        tmnId: user?.id || "",
        errorCode: code,
        errorMessage: message
      })
      return
    } else if (trackCode && trackMessage) {
      trackTMNCoinError({
        tmnId: user?.id || "",
        errorCode: trackCode,
        errorMessage: trackMessage
      })
      return
    }
  }, [trackCode, trackMessage, code, message])

  return (
    <div className="h-screen">
      <a className="flex justify-end h-[51px]" href="ascendmoney://closewebview">
        <Button data-testid="top-navbar-close-btn" variant="ghost" size="icon">
          <IcClose className="w-6 h-6" />
        </Button>
      </a>
      <div className="flex flex-col items-center gap-6 h-full pt-16">
        <img
          src="/images/fail.png"
          alt="We're down for maintenance"
          width={281}
          height={184}
        />
        <div className="flex flex-col items-center max-w-[343px] gap-2">
          <h2 className="headline">{t("title")}</h2>
          <span className="body-large text-tmn-black-tint-30 text-center">
            <Trans>{t("description")}</Trans>
          </span>
        </div>
        <a href="ascendmoney://closewebview">
          <Button
            variant="outline"
            className="text-tmn-orange-tint-0 border-tmn-orange-tint-0 min-w-[202px] hover:bg-white hover:text-tmn-orange-tint-0"
            style={{
              borderRadius: "100px"
            }}
          >
            {t("backBtn")}
          </Button>
        </a>
      </div>
    </div>
  )
}
