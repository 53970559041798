import IcClose from "@/assets/icons/ic_close.svg?react"
import IcChevLeft from "@/assets/icons/ic_chev_left.svg?react"
import IcThreeDot from "@/assets/icons/ic_three_dot.svg?react"
import { Button } from "./ui/button"
import useAppConfigStore from "@/store/useAppConfigStore"
import { Drawer, DrawerContent, DrawerTrigger } from "./ui/drawer"
import IcChevRight from "@/assets/icons/ic_chev_right.svg?react"
import Divider from "./Divider"
import { useTranslation } from "react-i18next"
import { trackTMNCoinClickButton } from "@/api/tracking.api"

const TopNavbar = () => {
  const { t } = useTranslation(["translation"])
  const { title, isEnableThreeDot, onClickBackTopNavBar, isEnableNavbar, appVersion } =
    useAppConfigStore()

  if (!isEnableNavbar) {
    return null
  }

  return (
    <div
      className="h-[51px] flex justify-between items-center bg-white z-[999] fixed top-0 left-0 w-full"
      style={{ borderBottom: "1px solid #E2E3E5" }}
    >
      <div className={`${isEnableThreeDot ? "w-20" : ""}`}>
        <Button
          data-testid="top-navbar-back-btn"
          variant="ghost"
          size="icon"
          onClick={onClickBackTopNavBar}
        >
          <IcChevLeft className="w-6 h-6" />
        </Button>
      </div>

      <span className="title-strong">{title}</span>
      <div className="flex">
        {isEnableThreeDot && (
          <Drawer>
            <DrawerTrigger className="w-10 h-10 outline-none">
              <IcThreeDot className="w-6 h-6 m-[auto]" />
            </DrawerTrigger>
            <DrawerContent
              data-testid="top-navbar-drawer-content"
              className="min-h-[unset] max-h-[unset]"
            >
              <div className="px-4">
                <a
                  href="https://support.truemoney.com/article-categories/tmn_coin/"
                  target="_self"
                  data-testid="top-navbar-drawer-item-1"
                  className="py-[18px] flex flex-row justify-between cursor-pointer"
                  onClick={() => {
                    trackTMNCoinClickButton({ buttonName: "FAQ" })
                  }}
                >
                  <span className="body-strong text-tmn-black-tint-10">{t("faq")}</span>
                  <IcChevRight />
                </a>
                <Divider />

                {/* <a
                  href="https://www.truemoney.com/coin"
                  target="_self"
                  data-testid="top-navbar-drawer-item-1"
                  className="py-[18px] flex flex-row justify-between cursor-pointer"
                  onClick={() => {
                    trackTMNCoinClickButton({ buttonName: "How to" });
                  }}
                >
                  <span className="body-strong text-tmn-black-tint-10">
                    {t("howTo")}
                  </span>
                  <IcChevRight />
                </a>
                <Divider /> */}
                {/* <a
                  href="https://www.truemoney.com/terms-conditions/ "
                  target="_self"
                  data-testid="top-navbar-drawer-item-2"
                  className="py-[18px] flex flex-row justify-between cursor-pointer"
                >
                  <span className="body-strong text-tmn-black-tint-10">
                    {t("tnc")}
                  </span>
                  <IcChevRight />
                </a>
                <Divider /> */}
                <div className="w-full flex justify-center p-4">
                  <span className="body-small text-tmn-black-tint-50">
                    {`${t("version")} ${appVersion}`}
                  </span>
                </div>
                <div className="h-12" />
              </div>
            </DrawerContent>
          </Drawer>
        )}

        <a href="ascendmoney://closewebview">
          <Button data-testid="top-navbar-close-btn" variant="ghost" size="icon">
            <IcClose className="w-6 h-6" />
          </Button>
        </a>
      </div>
    </div>
  )
}

export default TopNavbar
