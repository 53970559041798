import BottomNav from "./BottomNav"

type Props = {
  children: React.ReactNode
}
const MainLayout = ({ children }: Props) => {
  return (
    <>
      <div className="tmn-mainlayout overflow-scroll no-scrollbar">{children}</div>
      <BottomNav />
    </>
  )
}

export default MainLayout
