import { HistoryType, listPointTransactionsQueryOptions } from "@/api/common.api"
import { createFileRoute } from "@tanstack/react-router"
import { z } from "zod"

const ValidateSearch = (search: {}) => {
  return z
    .object({
      tab: z.nativeEnum(HistoryType).optional().default(HistoryType.RECEIVED),
      skip: z.number().optional().default(0),
      limit: z.number().optional().default(10)
    })
    .parse(search)
}

export type TypeofValidateSearch = ReturnType<typeof ValidateSearch>

export const Route = createFileRoute("/_transaction-history-layout/transaction-history/")(
  {
    loaderDeps: ({ search: { tab, limit, skip } }) => {
      return { tab, limit, skip }
    },
    loader: ({
      context: { queryClient },
      deps: { tab = HistoryType.RECEIVED, limit = 10, skip = 0 }
    }) =>
      queryClient.ensureQueryData(
        listPointTransactionsQueryOptions({
          forceRefresh: false,
          tab,
          limit,
          skip
        })
      ),

    validateSearch: ValidateSearch
  }
)
