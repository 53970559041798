import { Outlet, createFileRoute } from "@tanstack/react-router"
import TransactionHistoryLayout from "./_transaction-history-layout/-components/TransactionHistoryLayout"

export const Route = createFileRoute("/_transaction-history-layout")({
  component: LayoutComponent
})

function LayoutComponent() {
  return (
    <TransactionHistoryLayout>
      <Outlet />
    </TransactionHistoryLayout>
  )
}
