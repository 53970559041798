import axiosInstance from "@/utils/axiosInstance"
import { queryOptions } from "@tanstack/react-query"
import RequestError from "@/utils/RequestError"

export interface User {
  id: string // TMNID
  orgId: string
  account: string // Latitude account
  metadata: Record<string, any> // Other user metadata
  lastAccessAt?: Date // Optional LastAccessAt property
}

export type ListPage<T> = {
  items: T[]
  total: number
}

export type Rule = {
  id: string
  name: string
  cap: string
  icon: string
}

export type Earnings = {
  amount: number
  bonus: number
}

export type EarningsRule = {
  rule: Rule
  earnings: Earnings
}

export type BalanceResponse = {
  account: string
  currencyAddress: string
  balance: number
  validity?: {
    amount: number
    date: string
  }[]
  program: {
    name: string
    symbol: string
    image: string
  }
  expiry?: string
  userID: string
}

export type Translations = {
  en?: string
  th: string
}

type PointProgress = {
  id: number
  orgId: string
  name: Translations
  max: number
  earned: number
  tnc: Translations
  descriptions: Translations
  icon?: string
  order: number
}

export type PointProgressResponse = {
  items: PointProgress[]
}

export enum HistoryType {
  RECEIVED = "credit",
  USED = "debit",
  EXPIRED = "expired"
}

export type Transaction = {
  id: string
  orgID: string
  userID: string
  kind: HistoryType
  hash: string
  amount: number
  status: string
  metadata: {
    title: Translations
    desc?: Translations
    icon?: string
  }
  createdAt: string
  updatedAt: string
}

export type TransactionResponse = {
  items: Transaction[]
  total: number
}

type RuleDetailResponse = {
  id: string
  orgId: string
  content: RuleContent
}

type RuleContent = {
  ruleName: Translations
  ruleType: string
  ruleIcon: string
  ruleDescriptions: Translations
  tnc: Translations
  actions: Action[]
  earnings: EarningRule
  cap: Cap
  scheduleConfig: ScheduleConfig
  txDesc: Translations
  status: string
}

type Action = {
  actionEvent: string
  actionFilter: any[] // Update this with the actual type of the items in the array if known
}

type EarningRule = {
  type: string
  metric: string
  tier: number
  points: number
}

type Cap = {
  duration: string
  max: number
  timezone: string
}

type ScheduleConfig = {
  scheduleType: string
  startDate: string
  endDate: string
}

type UserSpending = {
  id: string
  periodId: string
  userId: string
  orgId: string
  amount: number
  count: number
  tier: string
  maximumCount: number
  maximumSpend: number
  periodEndDate: string
  createdAt: string
  updatedAt: string
}

export type PublishersToken = {
  mixpanel: MixpanelToken
  braze: BrazeToken
}

type MixpanelToken = {
  projectToken: string
}

export type BrazeToken = {
  apiKey: string
  restEndpoint: string
}

export type Faro = {
  apiKey: string
  appName: string
  url: string
  isEnable: boolean
}

export type AppConfigs = {
  publishersToken: PublishersToken
  faro: Faro
  isEnableChallenge: boolean
  version: string
}

export async function fetchProfile() {
  try {
    const res = await axiosInstance.get<User>("/users/profile")
    return res.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const profileQueryOptions = queryOptions({
  queryKey: ["profile"],
  queryFn: fetchProfile,
  refetchOnWindowFocus: false
})

export async function updateMetadata(metadata: Record<string, any>) {
  try {
    const res = await axiosInstance.post(`/users/metadata`, metadata)
    return res.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function fetchIsProfileCreated() {
  try {
    const res = await axiosInstance.get("/user/is-profile-created")
    return res.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const isProfileCreatedQueryOptions = queryOptions({
  queryKey: ["isProfileCreated"],
  queryFn: fetchIsProfileCreated,
  enabled: true,
  retry: 9, // will call API for 10 times (9 retry + 1 initial call)
  retryDelay: 1000
})

export async function fetchBalance() {
  try {
    const resBalance = await axiosInstance.get<BalanceResponse>("/points/balance") // ERR_WALLET_ADDRESS
    const resPointProgress =
      await axiosInstance.get<PointProgressResponse>("/points/earnings") // ERR_BALANCE_PROGRESS
    return {
      balanceResponseData: resBalance.data,
      pointProgressResponseData: resPointProgress.data
    }
  } catch (error) {
    console.error(error)
    throw new RequestError("ERR_BALANCE_PROGRESS", {
      errorCode: "ERR_BALANCE_PROGRESS",
      errorMessage: "Failed to retrieve current coin balance or earning rule progress."
    })
  }
}

const fetchAppConfigs = async () => {
  try {
    const response = await axiosInstance.get<AppConfigs>("/app-configs")
    return {
      ...response?.data,
      version: response.headers["x-api-version"]
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const appConfigsQueryOptions = queryOptions({
  queryKey: ["appConfigs"],
  queryFn: fetchAppConfigs,
  refetchOnWindowFocus: false
})

export const balanceQueryOptions = queryOptions({
  queryKey: ["balance"],
  queryFn: fetchBalance,
  refetchOnWindowFocus: false,
  throwOnError: true,
  // networkMode: "offlineFirst",
  gcTime: 5 * 60 * 1000, // 5 minutes
  staleTime: 5 * 60 * 1000 // 5 minutes
})

type ListPointTransactionsParams = {
  forceRefresh: boolean
  tab: HistoryType
  skip: number
  limit: number
}
export const fetchListPointTransactions = async (params: ListPointTransactionsParams) => {
  const headers = params.forceRefresh
    ? {
        "cache-control": "no-cache"
      }
    : {}
  try {
    const res = await axiosInstance.get<TransactionResponse>("/points/transactions", {
      headers,
      params: {
        filter: params.tab,
        skip: params.skip || 0,
        limit: params.limit || 10
      }
    })
    return res.data
  } catch (error) {
    console.error(error)
    throw new RequestError("ERR_TRANSACTIONS", {
      errorCode: "ERR_TRANSACTIONS",
      errorMessage: "Failed to retrieve coin transactions."
    })
  }
}

export const listPointTransactionsQueryOptions = (
  params: ListPointTransactionsParams
) => {
  return queryOptions({
    queryKey: ["listPointTransactions", params],
    queryFn: () => fetchListPointTransactions({ ...params }),
    refetchOnWindowFocus: false
    // enabled: false,
  })
}

export const fetchRuleDescriptionById = async (ruleId: string) => {
  try {
    const res = await axiosInstance.get<RuleDetailResponse>(`/points/rule`, {
      params: { "rule-id": ruleId }
    })
    return res.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const ruleDescriptionQueryOptions = (ruleId: string) => {
  return queryOptions({
    queryKey: ["ruleDescription", ruleId],
    queryFn: () => fetchRuleDescriptionById(ruleId),
    refetchOnWindowFocus: false,
    enabled: false
  })
}

export enum TransactionType {
  DEBIT = "debit",
  CREDIT = "credit"
}

export async function fetchHistory() {
  return axiosInstance.get("/history")
}

export async function fetchExpiryHistory() {
  throw new Error("Not implemented")
}

export async function fetchCashbackCatalogue() {
  try {
    const res = await axiosInstance.get("/cashbacks/catalogue")
    return res.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

// export async function fetchEarnings(): Promise<ListPage<EarningsRule>> {
//   try {
//     const res = await axiosInstance.get('/points/earnings')
//     return res.data
//   } catch (error) {
//     console.error(error)
//     throw error
//   }
// }

export async function orderCashback(cashbackId: string) {
  try {
    const res = await axiosInstance.get("/cashbacks/catalogue")
    console.log(cashbackId)
    return res.data.items
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function fetchUserTier() {
  try {
    const resp = await axiosInstance.get<UserSpending>("/profile/tier")
    return resp.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const userTierQueryOptions = queryOptions({
  queryKey: ["userTier"],
  queryFn: fetchUserTier,
  refetchOnWindowFocus: false
})
