import { Outlet, createFileRoute } from "@tanstack/react-router"
import CommonLayout from "./_common-layout/-components/CommonLayout"

export const Route = createFileRoute("/_common-layout")({
  component: LayoutComponent
})

function LayoutComponent() {
  return (
    <CommonLayout>
      {/* <div id="tmn-hide-navbar"></div> */}
      <Outlet />
    </CommonLayout>
  )
}
